import React from 'react'

import Label from './Label'
import { twcn } from '@util/tw'

export const classNames = error =>
  twcn(
    'border-solid border-gray-400 border rounded p-2 focus:outline-none focus:border-blue-600 w-full',
    { 'border-red-400 focus:border-red-400': error },
  )

const Input = ({ label, error, ...rest }, ref) => (
  <Label label={label} error={error}>
    <input className={classNames(error)} {...rest} ref={ref} />
  </Label>
)

export default React.forwardRef(Input)
