import React from 'react'
import cn from 'classnames'

import tones from '@util/toneColors'

const Line = ({ line }) => {
  const isAgent = line.user.type === 'agent'
  return (
    <>
      <div
        className={cn('flex max-w-sm', {
          'ml-auto': isAgent,
          'mr-auto': !isAgent,
          'flex-row-reverse': isAgent,
        })}
      >
        <div className="border rounded bg-gray-500 text-black p-1 text-sm select-none">
          {line.user.type}
        </div>
        <div>
          <div
            className={cn('p-1 flex flex-col', {
              'flex-row-reverse': isAgent,
            })}
          >
            <span
              className={cn({
                'ml-1': isAgent,
                'mr-1': !isAgent,
              })}
            >
              {line.text}
            </span>
            <div className={'flex'}>
              {line.tones.map(tone => (
                <div
                  key={tone.tone_id}
                  className={cn(
                    'h-2 flex-grow flex-shrink',
                    tones[tone.tone_id],
                    {
                      'ml-px': !isAgent,
                      'mr-px': isAgent,
                    },
                  )}
                  style={{
                    opacity: tone.score >= 0.75 ? 1 : 0.75,
                  }}
                  title={`${tone.tone_name}: ${
                    tone.score >= 0.75 ? 'High Likelihood' : 'Present'
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Line
