import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { format } from 'date-fns'

import Button from '@components/Button'
import Link from '@components/Link'
import Input from '@components/form/Input'
import Checkbox from '@components/form/Checkbox'
import useFirebase from '@hooks/useFirebase'
import call from '@util/call'
import { useToasts } from 'react-toast-notifications'

const Form = ({}) => {
  const { addToast } = useToasts()
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    agreed: false,
  })
  const setField = (key, val) => setForm(s => ({ ...s, [key]: val }))

  const [errors, setErrors] = useState({})
  const setError = (key, val) => setErrors(s => ({ ...s, [key]: val }))

  const [loading, setLoading] = useState(false)

  const firebase = useFirebase()

  const handleSubmit = async evt => {
    evt.preventDefault()
    setErrors({})
    setLoading(true)

    const { name, email, password } = form

    let cred

    try {
      cred = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)

      await call('/api/user', {
        method: 'post',
        body: {
          name,
          uid: cred.user.uid,
        },
      })

      cred.user.sendEmailVerification({
        url: window.location.origin + '/app',
      })

      navigate('/app')
      firebase.analytics().logEvent('sign_up', { method: 'web' })
      return
    } catch (err) {
      switch (err.code) {
        case 'auth/email-already-in-use':
        case 'auth/invalid-email': {
          setError('email', err.message)
          break
        }
        case 'auth/weak-password': {
          setError('password', err.message)
          break
        }
        default: {
          if (cred && cred.user) {
            await cred.user.delete()
          }
          // throw err
          addToast('Error creating your account!', { appearance: 'error' })
        }
      }
    }

    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label="First and Last Name"
        placeholder="Your full name"
        type="text"
        required
        value={form.name}
        onChange={evt => setField('name', evt.target.value)}
      />
      <Input
        label="Email Address"
        placeholder="you@provider.com"
        type="email"
        required
        value={form.email}
        onChange={evt => setField('email', evt.target.value)}
        error={errors.email}
      />
      <Input
        label="Password"
        placeholder="Your password"
        type="password"
        required
        value={form.password}
        onChange={evt => setField('password', evt.target.value)}
        error={errors.password}
      />
      <Checkbox
        label={
          <>
            I have read and agree to the <Link to="/legal/terms">Terms</Link>{' '}
            and the <Link to="/legal/privacy">Privacy Policy</Link>
          </>
        }
        required
        checked={form.agreed}
        onChange={() => setField('agreed', !form.agreed)}
      />
      <Button type="submit" primary disabled={loading} loading={loading} full>
        Sign Up
      </Button>
    </form>
  )
}

export default Form
