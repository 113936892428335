import React from 'react'
import { Router } from '@reach/router'

import Home from './Home'

const Agents = () => {
  return (
    <>
      <Router>
        <Home default />
      </Router>
    </>
  )
}

export default Agents
