import React from 'react'
import useSWR from 'swr'
import * as R from 'ramda'
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa'
import { mean } from 'd3-array'

import useAuth from '@hooks/useAuth'
import useFirebase from '@hooks/useFirebase'
import call from '@util/call'
import EntitySentiment from '@components/entity/EntitySentiment'
import Button from '@components/Button'

import { FlexPlot } from '@xmatters/vizlib'
import Line from './Line'
import Radar from './Radar'
import Legend from './Legend'

const SortButton = ({ children, by, sort, setSort, ...rest }) => (
  <Button
    onClick={() =>
      setSort({
        by,
        order: sort.by === by && sort.order === 'desc' ? 'asc' : 'desc',
      })
    }
    {...rest}
    className="inline-flex items-center"
  >
    {children}
    {sort.by === by && (
      <div className="ml-4">
        {sort.order === 'asc' ? <FaSortAmountUp /> : <FaSortAmountDown />}
      </div>
    )}
  </Button>
)

const Lines = ({ entities }) => {
  const [avg, magnitude] = React.useMemo(
    () =>
      R.pipe(
        R.chain(([n, ents]) =>
          ents.map(d => ({
            date: d.date,
            score: d.sentiment.score,
            magnitude: d.sentiment.magnitude,
          })),
        ),
        R.groupBy(d => d.date),
        R.map(d => ({
          score: mean(d.map(dd => dd.score)),
          magnitude: mean(d.map(dd => dd.magnitude)),
        })),
        R.toPairs,
        R.sortBy(d => d[0]),
        d => [
          d.map(d => [new Date(d[0]), d[1].score]),
          d.map(d => [new Date(d[0]), d[1].magnitude]),
        ],
      )(entities),
    [entities],
  )

  return (
    <>
      <div className="h-32 relative">
        <FlexPlot margin={35}>
          <Line
            data={[['avg', avg]]}
            yDomain={[-1, 1]}
            yTicks={[0, -0.5, -1, 0.5, 1]}
          />
        </FlexPlot>
        <Legend keys={[{ color: 'red', label: 'Sentiment' }]} />
      </div>
      <div className="h-32 relative">
        <FlexPlot margin={35}>
          <Line data={[['magnitude', magnitude]]} />
        </FlexPlot>
        <Legend keys={[{ color: 'red', label: 'Magnitude' }]} />
      </div>
    </>
  )
}

const Agent = ({ agentId, setName }) => {
  const { auth } = useAuth()
  const { data: details } = useSWR(`/api/agents/${agentId}`, async key => {
    const token = await auth.getIdToken()
    return call(key, { authToken: token })
  })

  const firebase = useFirebase()
  const { data: agent } = useSWR(agentId, async key => {
    const snap = await firebase
      .firestore()
      .collection('agents')
      .where('id', '==', key)
      .where('userId', '==', auth.uid)
      .get()

    return snap.docs[0].data()
  })

  React.useEffect(() => {
    setName(agent.name)
  }, [agent.name])

  const [sort, setSort] = React.useState({
    by: 'frequency',
    order: 'desc',
  })

  return (
    <>
      <div className="flex">
        <div className="w-1/2">
          <H3>Tones</H3>
          <div className="h-64 relative">
            <FlexPlot margin={20}>
              <Radar
                data={React.useMemo(
                  () =>
                    [
                      'excited',
                      'polite',
                      'sympathetic',
                      'satisfied',
                      'sad',
                      'frustrated',
                      'impolite',
                    ].map(tone => ({
                      received: details.tone.received.data[tone] || 0,
                      sent: details.tone.sent.data[tone] || 0,
                      tone: tone,
                    })),
                  [details.tone],
                )}
                series={[
                  { key: 'received', color: 'red' },
                  { key: 'sent', color: 'green' },
                ]}
                valueKey="tone"
              />
            </FlexPlot>
            <Legend
              keys={[
                { color: 'red', label: 'Received' },
                { color: 'green', label: 'Sent' },
              ]}
            />
          </div>
        </div>
        <div className="w-1/2">
          <H3>Sentiment</H3>
          <div className="h-64 flex flex-col">
            <Lines entities={details.entities} />
          </div>
        </div>
      </div>

      <div>
        <H3>Entities</H3>
        <SortButton sort={sort} by="frequency" setSort={setSort}>
          Frequency
        </SortButton>
        <SortButton sort={sort} by="salience" setSort={setSort}>
          Avg. Salience
        </SortButton>
        <SortButton sort={sort} by="score" setSort={setSort}>
          Avg. Sentiment Score
        </SortButton>
        <SortButton sort={sort} by="magnitude" setSort={setSort}>
          Avg. Sentiment Magnitude
        </SortButton>

        <EntitySentiment
          entities={React.useMemo(() => {
            const sortFn = {
              frequency: {
                asc: ([key, d]) => d.length,
                desc: ([key, d]) => -d.length,
              },
              salience: {
                asc: ([key, d]) => mean(d.map(dd => dd.salience)),
                desc: ([key, d]) => -mean(d.map(dd => dd.salience)),
              },
              score: {
                asc: ([key, d]) => mean(d.map(dd => dd.sentiment.score)),
                desc: ([key, d]) => -mean(d.map(dd => dd.sentiment.score)),
              },
              magnitude: {
                asc: ([key, d]) => mean(d.map(dd => dd.sentiment.magnitude)),
                desc: ([key, d]) => -mean(d.map(dd => dd.sentiment.magnitude)),
              },
            }

            const res = R.sortBy(sortFn[sort.by][sort.order])(details.entities)
            return res
          }, [details.entities.length, sort])}
        />
      </div>
    </>
  )
}

import Page from '@components/Page'
import { H3 } from '@components/typography'

export default ({ agentId }) => {
  const [name, setName] = React.useState()

  return (
    <Page title="Agent Details" subtitle={name}>
      <Agent agentId={agentId} setName={setName} />
    </Page>
  )
}
