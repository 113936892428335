import React from 'react'
import { mutate } from 'swr'

export default function useHandleRedirect(setError) {
  React.useEffect(() => {
    if (new RegExp('failed=true').test(window.location.search)) {
      setError(true)
    }
    if (new RegExp('success=true').test(window.location.search)) {
      mutate('sources')
      mutate('conversations')
      mutate('agents')
      mutate('entities')
    }
  }, [])
}
