import React from 'react'
import { Link } from 'gatsby'
import { MdLock } from 'react-icons/md'

import Page from '@components/Page'
import Button from '@components/Button'
import { Feature } from '@components/pricing/PriceCard'
import useAuth from '@hooks/useAuth'
import { planId, planByIdAndAnnual } from '@util/plan'
import tw from '@util/tw'

import Preview from './Preview'
import { PlanCard } from './PlanCard'
import Checkbox, { CheckMark } from './Checkbox'
import { Billing } from '../Billing'
import Spinner from '@components/Spinner'

const BigCheckMark = tw(CheckMark, 'text-3xl mx-auto')

const PlanPicker = ({ setPlan, planSize, status, wanted }) => (
  <>
    <PlanCard
      size="Small"
      prices={[39, 49]}
      features={
        <>
          <Feature>1 source</Feature>
          <Feature>250 conversations / mo</Feature>
          <Feature>Past 14-days history</Feature>
          <Feature>Daily sync</Feature>
        </>
      }
      onClick={() => setPlan('small')}
      isTrial={status === 'trialing'}
      isPlan={planSize === 'small'}
      bottom={<BigCheckMark checked={wanted === 'small'} />}
    />
    <PlanCard
      size="Medium"
      prices={[79, 99]}
      features={
        <>
          <Feature>2 sources</Feature>
          <Feature>1,000 conversations / mo</Feature>
          <Feature>Past month history</Feature>
          <Feature>Daily sync</Feature>
        </>
      }
      onClick={() => setPlan('medium')}
      isTrial={false}
      isPlan={planSize === 'medium'}
      bottom={<BigCheckMark checked={wanted === 'medium'} />}
    />
    <PlanCard
      size="Large"
      prices={[149, 199]}
      features={
        <>
          <Feature>All sources</Feature>
          <Feature>5,000 conversations / mo</Feature>
          <Feature>Past 6-month history</Feature>
          <Feature>Daily sync</Feature>
        </>
      }
      onClick={() => setPlan('large')}
      isTrial={false}
      isPlan={planSize === 'large'}
      bottom={<BigCheckMark checked={wanted === 'large'} />}
    />
    <PlanCard
      size="Custom"
      prices={[79, 99]}
      features={
        <>
          <Feature>1500 conversations</Feature>
          <Feature>Past month history</Feature>
          <Feature>Daily sync</Feature>
        </>
      }
      onClick={() => {}}
      isTrial={false}
      isPlan={false}
    />
  </>
)

const StepTitle = tw('h3', 'text-gray-600 font-semibold mt-4 mb-2')

import call from '@util/call'
import { mutate } from 'swr'
import { useToasts } from 'react-toast-notifications'

const Plan = () => {
  const { user, auth } = useAuth()
  const planSize = planId[user.stripe.plan]
  const hasPayment = user?.stripe?.hasPaymentMethod
  const [plan, setPlan] = React.useState(planSize)
  const [annual, setAnnual] = React.useState(true)
  const [submitting, setSubmitting] = React.useState(false)
  const { addToast } = useToasts()

  const onSubmit = async failed => {
    setSubmitting(true)

    if (failed) {
      console.log('failll')
      addToast('nahhh', {
        appearance: 'error',
      })
      setSubmitting(false)
      return
    }

    const authToken = await auth.getIdToken()
    const ya = await call('/api/user/plan', {
      method: 'post',
      authToken,
      body: {
        uid: auth.uid,
        plan: planByIdAndAnnual[plan][annual.toString()],
      },
    })

    mutate('auth', undefined, true)
    setSubmitting(false)
  }

  return (
    <>
      <StepTitle className="mt-0 mb-0">Pick a plan.</StepTitle>
      <div className="flex flex-wrap">
        <PlanPicker
          planSize={planSize}
          setPlan={setPlan}
          status={user.stripe.status}
          wanted={planId[planByIdAndAnnual[plan][annual.toString()]]}
        />
      </div>

      <StepTitle>Pick the billing cycle.</StepTitle>
      <div className="flex">
        <Checkbox
          checked={annual}
          onClick={() => setAnnual(true)}
          className="mx-2"
          checkmarkClassName="text-xl"
        >
          <div className="flex flex-col">
            <span>Annual Billing</span>
            <span className="uppercase text-xs text-red-600 font-semibold">
              Save 20%
            </span>
          </div>
        </Checkbox>
        <Checkbox
          checked={!annual}
          onClick={() => setAnnual(false)}
          className="mx-2"
          checkmarkClassName="text-xl"
        >
          <span className="flex-shrink-0 flex-grow">Monthly Billing</span>
        </Checkbox>
      </div>

      {user.stripe.plan === planByIdAndAnnual[plan][annual.toString()] && (
        <>
          <StepTitle>
            This is your current plan. Select a different one to change it.
          </StepTitle>
          {!hasPayment && (
            <p>
              Want to keep this plan after your period?
              <Link to="/app/user/billing">Add a Payment Method</Link> to keep
              access.
            </p>
          )}
        </>
      )}

      {user.stripe.plan !== planByIdAndAnnual[plan][annual.toString()] && (
        <>
          <StepTitle>Payment preview.</StepTitle>
          <React.Suspense fallback={<Spinner />}>
            <Preview plan={plan} annual={annual} />
          </React.Suspense>

          {!hasPayment && (
            <>
              <StepTitle>Add payment method.</StepTitle>
              <Billing
                onSuccess={onSubmit}
                onFail={async () => onSubmit(true)}
                buttonText="Securely Pay"
                info={
                  <>
                    <div className="rounded bg-blue-300 border-blue-700 my-2 p-2 text-sm font-light text-white text-center">
                      <p>
                        Your card details are stored securely on our payment
                        provider Stripe, which is PCI certified. Card
                        information never touches our servers or databases.
                      </p>
                      <p>
                        By adding your card you authorise Sentimentor to send
                        instructions to the financial institution that issued
                        your card to take payments from your card account in
                        accordance with the Terms of Service.
                      </p>
                    </div>
                  </>
                }
              />
            </>
          )}

          {hasPayment && (
            <>
              <StepTitle>Ready to go!</StepTitle>
              <Button
                disabled={submitting}
                loading={submitting}
                primary
                full
                className="flex items-center justify-center"
                onClick={async () => onSubmit()}
              >
                <MdLock className="mr-1" /> Proce
              </Button>
            </>
          )}
        </>
      )}
    </>
  )
}

const PlanPage = () => (
  <Page
    title="Change Plan"
    subtitle="We have plans to accommodate teams big and small. You can change between plans any time."
    header={
      <Link to="/app/user">
        <Button>Cancel</Button>
      </Link>
    }
  >
    <Plan />
  </Page>
)

export default PlanPage
