import React from 'react'

const Legend = ({ keys }) => (
  <div className="absolute top-0 left-0 flex">
    {keys.map(({ color, label }) => (
      <div className="flex items-center mr-3" key={label}>
        <div
          className="h-2 w-2 mr-1"
          style={{
            background: color,
          }}
        />
        <div className="text-sm font-light">{label}</div>
      </div>
    ))}
  </div>
)

export default Legend
