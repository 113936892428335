import React from 'react'
import { Link } from 'gatsby'

import Page from '@components/Page'
import { styled } from '@util/tw'

import Routes from './Routes'

const TabLink = styled(Link, 'block py-2 px-4')

export default ({ id }) => {
  const [details, setDetails] = React.useState({
    id: '',
    sourceName: '',
  })

  return (
    <Page
      title={`Conversation ${details.id}`}
      subtitle={`Source: ${details.sourceName}`}
    >
      <ul className="flex border-b border-gray-400 text-gray-700 mb-6">
        <li>
          <TabLink
            to={`/app/conversations/${id}`}
            activeClassName="border-b-2 border-blue-400 text-blue-400"
          >
            Analysis
          </TabLink>
        </li>
        <li>
          <TabLink
            to={`/app/conversations/${id}/transcript`}
            activeClassName="border-b-2 border-blue-400 text-blue-400"
          >
            Transcript
          </TabLink>
        </li>
      </ul>
      <Routes id={id} setDetails={setDetails} />
    </Page>
  )
}
