export const planName = {
  [process.env.STRIPE_PLAN_SMALL_M]: 'Small (Monthly)',
  [process.env.STRIPE_PLAN_SMALL_Y]: 'Small (Yearly)',
  [process.env.STRIPE_PLAN_MEDIUM_M]: 'Medium (Monthly)',
  [process.env.STRIPE_PLAN_MEDIUM_Y]: 'Medium (Yearly)',
  [process.env.STRIPE_PLAN_LARGE_M]: 'Large (Monthly)',
  [process.env.STRIPE_PLAN_LARGE_Y]: 'Large (Yearly)',
}

export const planId = {
  [process.env.STRIPE_PLAN_SMALL_M]: 'small',
  [process.env.STRIPE_PLAN_SMALL_Y]: 'small',
  [process.env.STRIPE_PLAN_MEDIUM_M]: 'medium',
  [process.env.STRIPE_PLAN_MEDIUM_Y]: 'medium',
  [process.env.STRIPE_PLAN_LARGE_M]: 'large',
  [process.env.STRIPE_PLAN_LARGE_Y]: 'large',
}

export const planByIdAndAnnual = {
  small: {
    false: process.env.STRIPE_PLAN_SMALL_M,
    true: process.env.STRIPE_PLAN_SMALL_Y,
  },
  medium: {
    false: process.env.STRIPE_PLAN_MEDIUM_M,
    true: process.env.STRIPE_PLAN_MEDIUM_Y,
  },
  large: {
    false: process.env.STRIPE_PLAN_LARGE_M,
    true: process.env.STRIPE_PLAN_LARGE_Y,
  },
}
