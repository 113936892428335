const query = params =>
  Object.entries(params)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join('&')

const qs = (url, params) => `${url}?${query(params)}`

export default qs
