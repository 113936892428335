import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { FaTimes } from 'react-icons/fa'

import Page from '@components/Page'
import Button from '@components/Button'
import useAuth from '@hooks/useAuth'
import qs from '@util/qs'

import useHandleRedirect from './useHandleRedirect'

const AddIntercom = () => {
  const { auth } = useAuth()
  const [error, setError] = useState(false)

  useHandleRedirect(setError)

  return (
    <Page
      title="Add Intercom Source"
      subtitle="Adding a new Intercom workspace to pull in conversations from"
      header={
        <Link to="/app/sources/add">
          <Button className="flex items-center no-underline">
            <FaTimes className="inline-block mr-1" />
            Cancel
          </Button>
        </Link>
      }
    >
      {error && (
        <div className="bg-red-200 text-red-700 p-2 rounded inline-block my-2">
          <div className="font-semibold">
            There was an error returned from Intercom.
          </div>
          <div>Maybe try again?</div>
        </div>
      )}
      <a
        href={qs('https://app.intercom.io/oauth', {
          client_id: '19505932-d854-485b-b474-c5ab37e70b12',
          state: auth.uid,
          redirect_uri: 'http://localhost/api/intercom/oauth',
        })}
      >
        <img
          src="https://static.intercomassets.com/assets/oauth/primary-7edb2ebce84c088063f4b86049747c3a.png"
          srcSet="https://static.intercomassets.com/assets/oauth/primary-7edb2ebce84c088063f4b86049747c3a.png 1x, https://static.intercomassets.com/assets/oauth/primary@2x-0d69ca2141dfdfa0535634610be80994.png 2x, https://static.intercomassets.com/assets/oauth/primary@3x-788ed3c44d63a6aec3927285e920f542.png 3x"
        />
      </a>
    </Page>
  )
}

export default AddIntercom
