import React from 'react'

import Link from '@components/Link'
import { ReactComponent as Logo } from '@assets/Sentimentor.svg'

import Form from './Form'

const Signup = ({}) => {
  return (
    <div className="bg-gray-200 overflow-hidden">
      <header>
        <div className="bg-gray-200 flex p-6">
          <Link to="/" className="mx-auto">
            <Logo className="h-12 w-auto text-gray-700 fill-current" />
          </Link>
        </div>
      </header>
      <div className="text-center mb-3 text-xl text-gray-800">
        Hi there! <span>👋</span> Let's get you set up
      </div>
      <div className="mx-auto max-w-lg border border-white rounded-lg shadow-lg bg-white p-6 md:p-12 mb-20">
        <Form />
        <div className="border rounded border-gray-400 p-2 mt-6 text-center">
          Already have an account? <Link to="/app/login">Login</Link>.
        </div>
      </div>
    </div>
  )
}

export default Signup
