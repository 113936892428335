import React from 'react'
import { Link } from 'gatsby'
import { format, fromUnixTime } from 'date-fns/esm'
import useSWR from 'swr'
import { useToasts } from 'react-toast-notifications'

import useAuth from '@hooks/useAuth'
import { planName } from '@util/plan'
import { twcn, styled } from '@util/tw'
import Page from '@components/Page'
import Button from '@components/Button'
import call from '@util/call'

const Row = ({ label, value, className = '', ...rest }) => (
  <div className={twcn('mb-2', className)} {...rest}>
    <div className="text-lg">{label}</div>
    <div>{value}</div>
  </div>
)

const StatefulButton = ({ onClick, children, ...rest }) => {
  const [loading, setLoading] = React.useState(false)

  return (
    <Button
      {...rest}
      onClick={async () => {
        setLoading(true)
        await onClick()
        setLoading(false)
      }}
      disabled={loading}
      loading={loading}
    >
      {children}
    </Button>
  )
}

import { formatCurrency } from '@util/format'

const Billing = ({}) => {
  const { auth } = useAuth()
  const { data, mutate } = useSWR(
    () => (auth.getIdToken ? '/api/user/billing' : null),
    async key => {
      const authToken = await auth.getIdToken()
      return call(key, { method: 'get', authToken })
    },
  )
  const { addToast } = useToasts()

  return (
    <>
      <Row
        label="Payment Method"
        value={
          data.card ? (
            <>
              <p>You have a saved card ending in {data.card}</p>
              <StatefulButton
                onClick={async () => {
                  const authToken = await auth.getIdToken()
                  await call('/api/user/paymentMethod', {
                    method: 'delete',
                    authToken,
                  })
                  mutate({
                    ...data,
                    card: null,
                  })
                  addToast('Payment method removed.', {
                    appearance: 'success',
                  })
                }}
              >
                Remove Payment Method
              </StatefulButton>
            </>
          ) : (
            <>
              <p>
                You don't have a saved card. Without a valid payment method,
                your plan will expire after the next payment date.
              </p>
              <Link to="/app/user/billing">
                <Button primary>Add Payment Method</Button>
              </Link>
            </>
          )
        }
      />
      <Row label="Balance" value={formatCurrency(data.balance)} />
      <Row
        label="Next Payment Date"
        value={format(fromUnixTime(data.next), 'MMM do, yyyy')}
      />
      <Row label="Amount Due" value={formatCurrency(data.due)} />
    </>
  )
}

const Third = styled('div', 'w-full lg:w-1/3')
const Title = styled('div', 'text-2xl')

const Profile = () => {
  const { user } = useAuth()

  return (
    <>
      <div className="flex justify-between flex-wrap">
        <Third>
          <Title>Basics</Title>
          <Row label="Name" value={user.name} />
          <Row label="Email" value={user.email} />
          {/* <Row label="Password" value={<Button>Change Password</Button>} /> */}
        </Third>
        <Third>
          <Title>Plan Details</Title>
          <Row
            label="Current Plan"
            value={
              <>
                <p>{planName[user.stripe.plan]}</p>
                {/* <Link to="/app/user/plan">
                  <Button primary>Upgrade Plan</Button>
                </Link> */}
              </>
            }
          />
          <Row label="Conversations Per Month" value={user.maxConvosPerMonth} />
          <Row
            label="Status"
            value={
              user.stripe.status === 'trialing' ? 'Beta' : user.stripe.status
            }
            className="capitalize"
          />
          {/* {user.stripe.status === 'trialing' && (
            <Row
              label="Trial End"
              value={format(
                fromUnixTime(user.stripe.current_period_end),
                'MMM dd, yyyy',
              )}
            />
          )} */}
        </Third>
        <Third>
          {/* <Title>Billing</Title>
          <React.Suspense
            fallback={
              <>
                <Row label="Payment Method" value="..." />
                <Row label="Next Payment Date" value="..." />
                <Row label="Amount Due" value="..." />
              </>
            }
          >
            <Billing />
          </React.Suspense> */}
        </Third>
      </div>
    </>
  )
}

const ProfilePage = () => (
  <Page title="Account" subtitle="Manage your account details">
    <Profile />
  </Page>
)

export default ProfilePage
