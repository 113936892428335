import React from 'react'

export const OnPlan = ({ trial = false, isPlan = false }) =>
  isPlan && (
    <div
      className="bg-blue-400 text-sm text-white p-1 text-center"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        transform: 'rotate(45deg) translate(50%, 100%)',
        transformOrigin: '100% 0',
        width: '100%',
      }}
    >
      {trial ? 'Trialing' : 'Current'}
    </div>
  )
