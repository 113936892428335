import React, { useState, useEffect } from 'react'

import useAuth from '@hooks/useAuth'
import qs from '@util/qs'

const AddZoho = () => {
  const { auth } = useAuth()
  const [error, setError] = useState(false)

  useEffect(() => {
    if (new RegExp('failed=true').test(window.location.search)) {
      setError(true)
    }
  }, [])

  return (
    <>
      <div className="mb-2">
        <h1 className="text-2xl font-semibold">Add Zoho SalesIQ Source</h1>
        <h2 className="text-gray-600">
          Adding a new Zoho SalesIQ account to pull in conversations from
        </h2>
      </div>
      {error && (
        <div className="bg-red-200 text-red-700 p-2 rounded inline-block my-2">
          <div className="font-semibold">
            There was an error returned from Zoho.
          </div>
          <div>Maybe try again?</div>
        </div>
      )}
      <a
        href={qs('https://accounts.zoho.com/oauth/v2/auth', {
          response_type: 'code',
          access_type: 'offline',
          client_id: '1000.PSZ0TV15STDR2QXYU7XZG9RE3I90VH',
          scope: ['SalesIQ.portals.READ', 'SalesIQ.conversations.READ'].join(
            ',',
          ),
          redirect_uri: 'http://localhost:5000/api/zoho/oauth',
          state: auth.uid,
        })}
      >
        Add It
      </a>
    </>
  )
}

export default AddZoho
