import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { FaTimes } from 'react-icons/fa'

import Page from '@components/Page'
import Button from '@components/Button'
import useAuth from '@hooks/useAuth'
import qs from '@util/qs'

import useHandleRedirect from './useHandleRedirect'

export default function AddDrift() {
  const { auth } = useAuth()
  const [error, setError] = useState(false)

  useHandleRedirect(setError)

  return (
    <Page
      title="Add Drift Source"
      subtitle="Adding a new Drift Integration to pull in conversations from"
      header={
        <Link to="/app/sources/add">
          <Button className="flex items-center no-underline">
            <FaTimes className="inline-block mr-1" />
            Cancel
          </Button>
        </Link>
      }
    >
      {error && (
        <div className="bg-red-200 text-red-700 p-2 rounded inline-block my-2">
          <div className="font-semibold">
            There was an error returned from Drift.
          </div>
          <div>Maybe try again?</div>
        </div>
      )}
      <a
        href={qs('https://dev.drift.com/authorize', {
          response_type: 'code',
          client_id: 'd3qNfTdWaxItt1y9LDWGUN90zjwbyoB7',
          redirect_uri:
            process.env.NODE_ENV === 'development'
              ? 'https://sentimentor.ngrok.io/api/drift/oauth'
              : 'https://sentimentor.co/api/drift/oauth',
          state: auth.uid,
        })}
      >
        <Button primary>Connect Drift</Button>
      </a>
    </Page>
  )
}
