import React from 'react'
import { navigate } from 'gatsby'

import useAuth from '@hooks/useAuth'
import useFirebase from '@hooks/useFirebase'

const Authed = ({ component: Component, ...rest }) => {
  const { auth, user } = useAuth()
  const firebase = useFirebase()

  const qs = new URLSearchParams(window.location.search)
  if (qs.has('custom')) {
    const token = qs.get('custom')
    firebase
      .auth()
      .signOut()
      .then(() => firebase.auth().signInWithCustomToken(token))
  }

  if (!auth || !user) {
    navigate('/app/login', { replace: true })
    return null
  }

  return <Component {...rest} />
}

export default Authed
