import React, { useState, Suspense } from 'react'
import cn from 'classnames'
import { uniqBy } from 'ramda'

import Button from '@components/Button'
import Link from '@components/Link'
import SourceLogo from '@components/SourceLogo'
import DateEntry from '@components/DateEntry'

import useFirebase from '@hooks/useFirebase'
import useAuth from '@hooks/useAuth'

import toneColors from '@util/toneColors'

const Tone = ({ tone, className = '' }) => (
  <span
    className={cn(
      'p-1 rounded text-white text-sm',
      toneColors[tone.tone_id],
      className,
    )}
  >
    {tone.tone_name}
  </span>
)

import useSWR, { useSWRPages } from 'swr'
import { SourceRow } from '@components/Row'

function Conversation({ snap }) {
  const convo = snap.data()

  const firebase = useFirebase()
  const { data: sourceSnap } = useSWR(`sources/${convo.sourceId}`, key =>
    firebase
      .firestore()
      .doc(key)
      .get(),
  )

  return (
    <>
      <SourceRow
        sourceType={convo.type}
        title={<Link to={`/app/conversations/${snap.id}`}>{convo.id}</Link>}
        content={
          <>
            <DateEntry title="Created:" date={new Date(convo.created * 1000)} />
            <p className="text-gray-600 text-sm">
              Source: {sourceSnap.data().name}
            </p>
          </>
        }
        after={
          <div className="ml-5">
            {uniqBy(
              k => k.tone_id,
              convo.analysis.flatMap(line =>
                line.tones.filter(tone => tone.score >= 0.75),
              ),
            ).map((tone, i) => (
              <Tone
                tone={tone}
                key={i}
                className={twcn({
                  'ml-1': i > 0,
                })}
              />
            ))}
          </div>
        }
      />
    </>
  )
}

import Spinner from '@components/Spinner'

const List = ({}) => {
  const firebase = useFirebase()
  const { auth } = useAuth()

  const {
    pages,
    isReachingEnd,
    isLoadingMore,
    loadMore,
    isEmpty,
  } = useSWRPages(
    'conversations',
    function Page({ offset, withSWR }) {
      const { data: conversations } = withSWR(
        useSWR(['conversations', offset], async (str, startAfter) => {
          let query = firebase
            .firestore()
            .collection('conversations')
            .orderBy('created', 'desc')
            .where('userId', '==', auth.uid)
            .limit(25)

          if (startAfter) {
            query = query.startAfter(startAfter)
          }

          const querySnap = await query.get()
          return querySnap.docs
        }),
      )

      return conversations.map(snap => (
        <Conversation key={snap.id} snap={snap} />
      ))
    },
    ({ data: conversations }) =>
      conversations.length ? conversations[conversations.length - 1] : null,
    [],
  )

  if (isEmpty) {
    return (
      <p>
        No conversations found. <Link to="/app/sources/add">Add a Source</Link>{' '}
        to start syncing conversations from it.
      </p>
    )
  }

  return (
    <>
      {pages.map((page, i) => (
        <Suspense fallback={<Spinner />} key={i}>
          {page}
        </Suspense>
      ))}
      <Button
        onClick={loadMore}
        disabled={isReachingEnd || isLoadingMore}
        loading={isLoadingMore}
      >
        {isReachingEnd ? 'No more data' : 'Load More'}
      </Button>
    </>
  )
}

import Page from '@components/Page'
import { twcn } from '@util/tw'

export default () => (
  <Page title="Conversations" subtitle="See all conversations here">
    <List />
  </Page>
)
