import React from 'react'
import * as R from 'ramda'
import capitalize from 'lodash/capitalize'

import tones, { tonesRGB } from '@util/toneColors'

import {
  FlexPlot,
  usePlotContext,
  Axis,
  GridLines,
  Rects,
} from '@xmatters/vizlib'
import { scaleBand, scaleLinear } from 'd3-scale'
import { max } from 'd3-array'

const Bar = ({ data }) => {
  const { chartHeight, chartWidth } = usePlotContext()

  const xScale = scaleBand()
    .domain(data.map(d => d.tone))
    .range([0, chartWidth])
    .padding(0.2)
  const yScale = scaleLinear()
    .domain([0, max(data.map(d => d.count))])
    .range([chartHeight, 0])

  const yTicks = yScale.ticks().filter(Number.isInteger)

  return (
    <>
      <Axis
        scale={xScale}
        showDomain
        transform={`translate(0,${chartHeight})`}
      />
      <Axis scale={yScale} orientation="left" ticks={yTicks} />
      <GridLines
        scale={yScale}
        style={{ stroke: 'lightgray' }}
        ticks={yTicks}
      />
      <Rects
        data={data.map(d => [xScale(d.tone), yScale(d.count), d.tone])}
        width={() => xScale.bandwidth()}
        height={d => chartHeight - d[1]}
        keys={d => d[2]}
        style={d => ({ fill: tonesRGB[d[2].toLowerCase()] })}
      />
    </>
  )
}

const Tones = ({ conversation }) => {
  const data = React.useMemo(() => {
    const datz = R.pipe(
      R.map(sen => sen.tones),
      R.flatten,
      R.groupBy(to => to.tone_id),
      R.toPairs,
      R.map(([k, v]) => [k, v.length]),
      R.fromPairs,
    )(conversation.analysis)

    const fago = Object.keys(tones).map(tone => ({
      tone: capitalize(tone),
      count: datz[tone] || 0,
    }))

    return fago
  }, [conversation.updated])

  return (
    <>
      <FlexPlot margin={35}>
        <Bar data={data} />
      </FlexPlot>
    </>
  )
}

export default Tones
