import React from 'react'

import EntitySentiment from '@components/entity/EntitySentiment'
import SentimentTimeline from './SentimentTimeline'
import Tones from './Tones'

import * as R from 'ramda'
import { H3 } from '@components/typography'

const Analysis = ({ conversation, source }) => {
  const entities = React.useMemo(
    () =>
      R.pipe(
        R.groupBy(ent => ent.name),
        R.toPairs,
        // R.sortBy(([name, ents]) => -ents[0].salience),
        R.sortBy(
          ([name, ents]) =>
            -ents.reduce((sum, cur) => sum + cur.salience, 0) / ents.length,
        ),
      )(conversation.sentiment.entities),
    [conversation.sentiment.entities.length],
  )

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <H3>Sentiment Timeline</H3>
          <div className="h-64">
            <SentimentTimeline conversation={conversation} />
          </div>
        </div>
        <div>
          <H3>Tone</H3>
          <div className="h-64">
            <Tones conversation={conversation} />
          </div>
        </div>
      </div>

      <div className="mt-8">
        <H3>Entity Sentiment</H3>
        <div>
          <EntitySentiment entities={entities} />
        </div>
      </div>
    </>
  )
}

export default Analysis
