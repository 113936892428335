import { useRef, useState, useEffect } from 'react'
import usePopper from 'use-popper'

import mergeRefs from '@util/mergeRefs'

/**
 * @returns {[any, any, boolean, Function]}
 */
const usePopperOnClick = (popperProps = {}) => {
  const ref = useRef()
  const { reference, popper } = usePopper(popperProps)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const handler = evt => {
      if (!ref.current) return
      if (!ref.current.contains(evt.target)) setOpen(false)
    }
    document.addEventListener('click', handler)

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [])

  return [mergeRefs([ref, reference.ref]), popper, open, setOpen]
}

export default usePopperOnClick
