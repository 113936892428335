import React from 'react'
import { usePlotContext, Path, Axis } from '@xmatters/vizlib'
import { scaleLinear, scalePoint } from 'd3-scale'
import { lineRadial, areaRadial, curveCatmullRomClosed } from 'd3-shape'
import { max } from 'd3-array'
import { color as d3Color } from 'd3-color'

const xDomain = [
  'excited',
  'polite',
  'sympathetic',
  'satisfied',
  'sad',
  'frustrated',
  'impolite',
  '',
]

const Area = ({ data, line, area, color, points }) => {
  return (
    <g>
      <Path
        generator={line}
        data={data}
        style={{
          fill: 'none',
          stroke: d3Color(color).copy({ opacity: 0.7 }),
          strokeWidth: 2,
        }}
      />
      <Path
        generator={area}
        data={data}
        style={{ fill: d3Color(color).copy({ opacity: 0.3 }) }}
      />
      {points.map(([cx, cy], i) => (
        <circle
          key={`${cx}-${cy}-${i}`}
          fill={d3Color(color).copy({ opacity: 0.7 })}
          r="2"
          cx={cx}
          cy={cy}
        />
      ))}
    </g>
  )
}

const Radar = ({ data, series, valueKey }) => {
  const { chartHeight, chartWidth } = usePlotContext()
  const xScale = scalePoint()
    .domain(xDomain)
    .range([0, 2 * Math.PI])
  const yScale = scaleLinear()
    .domain([0, max(data.flatMap(d => series.map(serie => d[serie.key])))])
    .range([0, chartHeight / 2])
  const tickLine = lineRadial()
    .angle(d => xScale(d[0]))
    .radius(d => yScale(d[1]))

  const line = lineRadial()
    .angle(d => xScale(d[0]))
    .radius(d => yScale(d[1]))
    .curve(curveCatmullRomClosed)
  const area = areaRadial()
    .angle(d => xScale(d[0]))
    .outerRadius(d => yScale(d[1]))
    .curve(curveCatmullRomClosed)

  return (
    <>
      <g transform={`translate(${chartWidth / 2},${chartHeight / 2})`}>
        {yScale.ticks(4).map(tick => (
          <Path
            key={tick}
            generator={tickLine}
            data={xDomain.map(k => [k, tick])}
            style={{ fill: 'none', stroke: 'lightgray' }}
          />
        ))}
        <Axis
          orientation="right"
          scale={yScale.copy().range([chartHeight / 2, 0])}
          showDomain
          domainProps={{ style: { stroke: 'lightgray' } }}
          ticks={yScale.ticks(4)}
          transform={`translate(0,-${chartHeight / 2})`}
          tickFontSize={10}
        />
        {series.map(serie => (
          <Area
            key={serie.key}
            line={line}
            area={area}
            data={data.map(d => [d[valueKey], d[serie.key]])}
            points={data.map(d => [
              yScale(d[serie.key]) *
                Math.cos(xScale(d[valueKey]) - Math.PI / 2),
              yScale(d[serie.key]) *
                Math.sin(xScale(d[valueKey]) - Math.PI / 2),
            ])}
            color={serie.color}
          />
        ))}
        {xDomain.slice(0, -1).map(k => {
          const r = yScale(yScale.domain()[1]) * 1.1
          const theta = xScale(k) - Math.PI / 2

          const anchor = rad => {
            if (rad % Math.PI === 0) return 'middle'
            if (rad % (Math.PI / 2) === 0) return 'middle'
            if (rad < Math.PI / 2) return 'start'
            if (rad < Math.PI) return 'start'
            return 'end'
          }

          return (
            <text
              key={k}
              x={r * Math.cos(theta)}
              y={r * Math.sin(theta)}
              fontSize={10}
              textAnchor={anchor(xScale(k))}
            >
              {k}
            </text>
          )
        })}
      </g>
    </>
  )
}

export default Radar
