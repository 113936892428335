import React from 'react'

const Axis = ({
  orientation = 'bottom',
  scale,
  ticks = scale.ticks(),
  tickFormat = scale.tickFormat ? scale.tickFormat() : d => d,
  tickProps = {},
  markLength = 3,
  markProps = {},
  showDomain = false,
  domainProps = {},
  ...rest
}) => {
  return (
    <g {...rest}>
      {showDomain && (
        <line
          x1={{ bottom: 0, left: 0 }[orientation]}
          y1={{ bottom: 0, left: 0 }[orientation]}
          x2={{ bottom: scale(scale.domain()[1]), left: 0 }[orientation]}
          y2={{ bottom: 0, left: scale(scale.domain()[0]) }[orientation]}
          style={{
            fill: 'none',
            stroke: 'black',
          }}
          {...domainProps}
        />
      )}
      {ticks.map((tick, i) => (
        <g
          transform={
            orientation === 'top' || orientation === 'bottom'
              ? `translate(${scale(tick)},0)`
              : `translate(0,${scale(tick)})`
          }
          key={i}
        >
          <line
            x1={
              {
                left: -markLength,
                right: markLength,
                top: 0,
                bottom: 0,
              }[orientation]
            }
            y1={
              {
                left: 0,
                right: 0,
                top: -markLength,
                bottom: 0,
              }[orientation]
            }
            x2={
              {
                left: 0,
                right: markLength,
                top: 0,
                bottom: 0,
              }[orientation]
            }
            y2={
              {
                left: 0,
                right: 0,
                top: 0,
                bottom: markLength,
              }[orientation]
            }
            style={{
              stroke: 'black',
              strokeWidth: 1,
              fill: 'none',
            }}
            {...markProps}
          />
          <text
            dy={{ bottom: 14, left: 0 }[orientation]}
            dx={{ bottom: 0, left: -8 }[orientation]}
            fontSize="12px"
            textAnchor={
              {
                left: 'end',
                right: 'start',
                top: 'middle',
                bottom: 'middle',
              }[orientation]
            }
            dominantBaseline={
              {
                left: 'central',
                right: 'central',
                top: 'auto',
                bottom: 'auto',
              }[orientation]
            }
            {...{
              ...(typeof tickProps === 'function'
                ? tickProps(tick)
                : tickProps),
            }}
          >
            {tickFormat(tick)}
          </text>
        </g>
      ))}
    </g>
  )
}

export default React.memo(Axis)
