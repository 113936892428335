const tones = {
  excited: 'bg-yellow-400',
  frustrated: 'bg-red-500',
  impolite: 'bg-red-700',
  polite: 'bg-teal-500',
  sad: 'bg-indigo-500',
  satisfied: 'bg-green-500',
  sympathetic: 'bg-pink-500',
}

export const tonesRGB = {
  excited: '#f6e05e',
  frustrated: '#f56565',
  impolite: '#c53030',
  polite: '#38b2ac',
  sad: '#667eea',
  satisfied: '#48bb78',
  sympathetic: '#ed64a6',
}

export default tones
