import React from 'react'
import { useVirtual } from 'react-virtual'
import { max } from 'd3-array'

import Entity from './Entity'
import Button from '@components/Button'

const EntitySentiment = ({ entities = [] }) => {
  const parentRef = React.useRef()
  const rowVirtualizer = useVirtual({
    size: entities.length,
    parentRef,
    estimateSize: React.useCallback(() => 144, []),
    overscan: 3,
  })

  const maxMagnitude = React.useMemo(
    () =>
      max(
        entities.flatMap(([name, ents]) =>
          ents.map(d => d.sentiment.magnitude),
        ),
      ),
    [entities.length],
  )

  return (
    <>
      <div
        ref={parentRef}
        className="border rounded overflow-auto max-h-screen"
        style={{
          height: '32rem',
        }}
      >
        <div
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.virtualItems.map(row => {
            const [name, ents] = entities[row.index]

            return (
              <Entity
                name={name}
                data={ents}
                maxMagnitude={maxMagnitude}
                key={row.index}
                ref={row.measureRef}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${entities[row.index]}px`,
                  transform: `translateY(${row.start}px)`,
                }}
              />
            )
          })}
        </div>
        <div className="sticky bottom-0 pb-3 pr-3 flex justify-end">
          <Button onClick={() => rowVirtualizer.scrollToIndex(0)}>Top</Button>
        </div>
      </div>
    </>
  )
}

export default EntitySentiment
