import React from 'react'

import Spinner from '@components/Spinner'
import { twcn } from '@util/tw'

const Button = ({
  className = '',
  children = null,
  primary = false,
  disabled = false,
  full = false,
  loading = false,
  ...rest
}) => (
  <button
    className={twcn(
      'text-white font-semibold p-3 rounded',
      'bg-gray-500 hover:bg-gray-400',
      {
        'bg-green-500 hover:bg-green-400': primary,
        'bg-green-300 hover:bg-green-300 text-gray-200': primary && disabled,
        'bg-gray-300 hover:bg-gray-300 text-gray-200': disabled,
        'cursor-not-allowed': disabled,
        'w-full': full,
        'text-2xl flex justify-center': loading,
      },
      className,
    )}
    disabled={disabled}
    {...rest}
  >
    {loading ? <Spinner /> : children}
  </button>
)

export default Button
