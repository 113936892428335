import React from 'react'
import { scaleLinear, scaleTime, scaleOrdinal } from 'd3-scale'
import { max, extent } from 'd3-array'
import { line, curveMonotoneX, area } from 'd3-shape'
import { color as d3Color } from 'd3-color'
import { format } from 'd3-format'

import { GridLines, Axis, Path, usePlotContext } from '@xmatters/vizlib'

export const BottomAxis = ({ scale, ...rest }) => {
  const { chartHeight } = usePlotContext()

  return (
    <Axis
      orientation="bottom"
      showDomain
      scale={scale}
      transform={`translate(0,${chartHeight})`}
      tickFontSize={10}
      {...rest}
    />
  )
}

export const LeftAxis = ({ scale, ...rest }) => {
  return (
    <Axis
      orientation="left"
      scale={scale}
      tickFormat={format('.2~')}
      tickFontSize={10}
      {...rest}
    />
  )
}

export const Grid = ({ scale, ...rest }) => {
  return <GridLines scale={scale} style={{ stroke: 'lightgray' }} {...rest} />
}

export const AreaLineDots = ({ data, line, area, color }) => {
  return (
    <>
      <Path
        generator={line}
        data={data}
        style={{
          fill: 'none',
          stroke: d3Color(color).copy({ opacity: 0.7 }),
          strokeWidth: 2,
        }}
      />
      <Path
        generator={area}
        data={data}
        style={{
          fill: d3Color(color).copy({ opacity: 0.3 }),
        }}
      />
      {data.map((d, i) => (
        <circle key={`${name}-${i}`} cx={d[0]} cy={d[1]} fill={color} r={2} />
      ))}
    </>
  )
}

const Line = ({
  data,
  yDomain = [0, max(data.flatMap(([k, dd]) => dd.map(d => d[1])))],
  yTicks = null,
  colorScale = scaleOrdinal().range(['red', 'yellow']),
  bottomAxis = {},
  showBottomAxis = true,
}) => {
  const { chartHeight, chartWidth } = usePlotContext()
  const yScale = scaleLinear()
    .domain(yDomain)
    .range([chartHeight, 0])
    .nice()
  const xScale = scaleTime()
    .domain(extent(data.flatMap(([k, dd]) => dd.map(d => d[0]))))
    .range([0, chartWidth])

  const lineGenerator = line().curve(curveMonotoneX)
  const areaGenerator = area()
    .y0(d => yScale(0))
    .curve(curveMonotoneX)

  return (
    <>
      {showBottomAxis && <BottomAxis scale={xScale} {...bottomAxis} />}
      <LeftAxis scale={yScale} ticks={yTicks ?? yScale.ticks(4)} />
      <Grid scale={yScale} ticks={yTicks ?? yScale.ticks(4)} />
      {data.map(([key, dd]) => (
        <AreaLineDots
          key={key}
          area={areaGenerator}
          line={lineGenerator}
          color={colorScale(key)}
          data={dd.map(d => [xScale(d[0]), yScale(d[1])])}
        />
      ))}
    </>
  )
}

export default Line
