import React, { Suspense } from 'react'

import Spinner from '@components/Spinner'
import { H1, H2 } from '@components/typography'

const Page = ({ children, title, subtitle, header = null }) => (
  <div className="px-6 py-3">
    <div className="flex justify-between items-start mb-6">
      <div>
        <H1>{title}</H1>
        <H2>{subtitle}</H2>
      </div>

      {header}
    </div>

    <Suspense fallback={<Spinner className="text-3xl" />}>{children}</Suspense>
  </div>
)

export default Page
