import React from 'react'
import { twcn } from '@util/tw'

const Label = ({ label, error, children, className = '', ...rest }) => (
  <div className={twcn('mb-3', { 'text-red-700': error }, className)} {...rest}>
    <label className="text font-semibold block">
      <span className="block mb-1">{label}</span>
      {children}
      <span className="text-sm font-light">{error}</span>
    </label>
  </div>
)

export default Label
