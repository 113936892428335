import React from 'react'
import cn from 'classnames'

import { FaFileCsv } from 'react-icons/fa'

import intercom from '@assets/intercom.png'
import zoho from '@assets/zoho.png'
import drift from '@assets/drift.png'

const sources = {
  intercom,
  zoho,
  drift,
}

const SourceLogo = ({ type, className }) =>
  type === 'csv' ? (
    <FaFileCsv className={cn('text-green-600 h-auto', className)} />
  ) : (
    <img src={sources[type]} className={cn('h-auto', className)} />
  )

export default SourceLogo
