import React, { useState } from 'react'
import { IoIosChatboxes } from 'react-icons/io'
import {
  FaUser,
  FaDatabase,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
} from 'react-icons/fa'
import { MdNaturePeople } from 'react-icons/md'
import cn from 'classnames'
import { Link, navigate } from 'gatsby'
import { format } from 'date-fns'

import { ReactComponent as SentimentorLogo } from '@assets/Sentimentor.svg'
import { ReactComponent as Logo } from '@assets/Logo.svg'
import tw, { twcn } from '@util/tw'
import useAuth from '@hooks/useAuth'
import { planName } from '@util/plan'
import usePopperOnClick from '@hooks/usePopperOnClick'
import useFirebase from '@hooks/useFirebase'
import Portal from '@components/Portal'

const MenuHR = tw('hr', 'border-blue-200')

const NavLink = ({ to, icon, text, collapsed }) => (
  <li>
    <Link
      to={to}
      className={cn(
        'flex items-center px-4 py-2 cursor-pointer',
        'font-semibold text-blue-100',
        'hover:bg-blue-300 leading-relaxed',
        {
          'justify-center': collapsed,
        },
      )}
      activeClassName="bg-blue-300"
      partiallyActive
    >
      <span className="text-xl ml-3 my-1">{icon}</span>
      {!collapsed && <span className="ml-2">{text}</span>}
    </Link>
  </li>
)

const Item = tw(
  'li',
  'hover:bg-gray-200 cursor-pointer border border-transparent',
)

const ConvoGauge = ({ convos, max }) => {
  return (
    <div className={twcn('flex flex-row py-2 relative')}>
      <div
        style={{
          width: `${Math.min(100, (convos / max) * 100)}%`,
          backgroundColor: 'red',
        }}
        className="h-5 rounded-l"
      />
      <div
        style={{
          width: `${Math.min(100, 100 - (convos / max) * 100)}%`,
          backgroundColor: 'white',
        }}
        className={twcn('h-5 rounded-r', {
          'rounded-l': convos === 0,
        })}
      />
      <div className="absolute w-full text-center text-gray-600">
        {convos} / {max}
      </div>
    </div>
  )
}

const UserSection = ({ collapsed }) => {
  const { user } = useAuth()
  const [ref, popper, open, setOpen] = usePopperOnClick({
    placement: 'right-end',
  })
  const firebase = useFirebase()

  return (
    <div className="text-white text-sm py-1 px-3">
      <div className="grid grid-flow-col grid-rows-2 gap-1">
        <div
          className={twcn('row-span-1 col-span-2 font-bold', {
            hidden: collapsed,
          })}
        >
          {user.name}
        </div>
        <div className={twcn('row-span-1 col-span-2', { hidden: collapsed })}>
          Plan: {planName[user.stripe.plan]}
        </div>
        <div
          className="row-span-2 col-span-1 flex justify-center items-center cursor-pointer hover:bg-blue-300 rounded p-2"
          ref={ref}
          onClick={() => setOpen(!open)}
        >
          <FaCog />
        </div>
        {open && (
          <Portal>
            <div
              ref={popper.ref}
              style={popper.styles}
              data-placement={popper.placement}
            >
              <ul className="bg-white rounded border shadow-lg text-sm mt-1">
                <Item className="rounded-t">
                  <Link to="/app/user" className="py-2 px-4 flex flex-col">
                    <span className="text-gray-600 text-xs">Signed in as</span>
                    <span>{user.name}</span>
                  </Link>
                </Item>
                <li className="border border-b-0 border-l-0 border-r-0"></li>
                <Item
                  className="rounded-b py-2 px-4 flex flex-col text-red-600 hover:bg-red-600 hover:text-white"
                  onClick={() => {
                    firebase.auth().signOut()
                  }}
                >
                  Logout
                </Item>
              </ul>
            </div>
          </Portal>
        )}
      </div>
      {!collapsed && (
        <ConvoGauge
          convos={user.convosPerMonth[format(new Date(), 'yy-MM')] || 0}
          max={user.maxConvosPerMonth}
        />
      )}
    </div>
  )
}

const Menu = ({ className }) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <nav
      className={className}
      style={{
        width: collapsed ? '68px' : '256px',
      }}
    >
      <div className="text-white font-bold px-4 py-3">
        <Link to="/app">
          {collapsed ? (
            <Logo
              className="h-10 w-auto text-white fill-current"
              css={{
                stroke: 'black',
                strokeWidth: 5,
                strokeDasharray: '1500 1500',
                strokeDashoffset: 1500,
                transition: 'all 500ms',
                ':hover': {
                  strokeDashoffset: 0,
                },
              }}
            />
          ) : (
            <SentimentorLogo
              className="h-10 w-auto text-white fill-current"
              css={{
                stroke: 'black',
                strokeWidth: 5,
                strokeDasharray: '1500 1500',
                strokeDashoffset: 1500,
                transition: 'all 500ms',
                ':hover': {
                  strokeDashoffset: 0,
                },
              }}
            />
          )}
        </Link>
      </div>

      <MenuHR />

      <ul className="list-none flex-grow overflow-y-scroll mt-3">
        <NavLink
          to="/app/conversations"
          icon={<IoIosChatboxes />}
          text="Conversations"
          collapsed={collapsed}
        />
        <NavLink
          to="/app/agents"
          icon={<FaUser />}
          text="Agents"
          collapsed={collapsed}
        />
        <NavLink
          to="/app/entities"
          icon={<MdNaturePeople />}
          text="Entities"
          collapsed={collapsed}
        />
        <NavLink
          to="/app/sources"
          icon={<FaDatabase />}
          text="Sources"
          collapsed={collapsed}
        />
      </ul>

      <MenuHR />

      <UserSection collapsed={collapsed} />

      <MenuHR />

      <div
        className={twcn(
          'flex justify-end px-4 py-3 hover:bg-blue-300 cursor-pointer',
          {
            'justify-center': collapsed,
          },
        )}
        onClick={() => {
          setCollapsed(!collapsed)
        }}
      >
        {collapsed ? (
          <FaChevronRight className="text-white" />
        ) : (
          <FaChevronLeft className="text-white" />
        )}
      </div>
    </nav>
  )
}

export default Menu
