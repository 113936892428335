import React, { useState } from 'react'
import { navigate } from 'gatsby'

import Button from '@components/Button'
import Input from '@components/form/Input'
import useFirebase from '@hooks/useFirebase'

const Form = ({}) => {
  const [form, setForm] = useState({
    email: '',
    password: '',
  })
  const setField = (key, val) => setForm(s => ({ ...s, [key]: val }))

  const [errors, setErrors] = useState({})
  const setError = (key, val) => setErrors(s => ({ ...s, [key]: val }))

  const [loading, setLoading] = useState(false)

  const firebase = useFirebase()

  const handleSubmit = async evt => {
    evt.preventDefault()
    setErrors({})
    setLoading(true)

    const { email, password } = form

    let cred

    try {
      const cred = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)

      navigate('/app')
      firebase.analytics().logEvent('login', { method: 'web' })
      return
    } catch (err) {
      switch (err.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found': {
          setError('email', err.message)
          break
        }
        case 'auth/wrong-password': {
          setError('password', err.message)
          break
        }
        default: {
        }
      }
    }

    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label="Email Address"
        placeholder="you@provider.com"
        type="email"
        required
        value={form.email}
        onChange={evt => setField('email', evt.target.value)}
        error={errors.email}
      />
      <Input
        label="Password"
        placeholder="Your password"
        type="password"
        required
        value={form.password}
        onChange={evt => setField('password', evt.target.value)}
        error={errors.password}
      />
      <Button type="submit" primary disabled={loading} loading={loading} full>
        Login
      </Button>
    </form>
  )
}

export default Form
