import React from 'react'
import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa'

import { twcn } from '@util/tw'

export const CheckMark = ({ checked, className = '' }) =>
  checked ? (
    <FaRegCheckCircle className={twcn('text-green-500', className)} />
  ) : (
    <FaRegCircle className={twcn('text-gray-500', className)} />
  )

const Checkbox = ({
  checked,
  children = null,
  className = '',
  checkmarkClassName = '',
  ...rest
}) => (
  <div
    className={twcn(
      'w-1/2 flex items-center cursor-pointer border rounded p-2 hover:shadow',
      className,
    )}
    {...rest}
  >
    <CheckMark
      checked={checked}
      className={twcn('mr-2 text-lg', checkmarkClassName)}
    />
    {children}
  </div>
)

export default Checkbox
