import React from 'react'

import Analysis from './Analysis'
import Transcript from './Transcript'

import useSWR from 'swr'
import { Router } from '@reach/router'
import useFirebase from '@hooks/useFirebase'

const Routes = ({ id, setDetails }) => {
  const firebase = useFirebase()
  const { data: convoSnap } = useSWR(`conversations/${id}`, key =>
    firebase
      .firestore()
      .doc(key)
      .get(),
  )
  const conversation = convoSnap.data()

  const { data: sourceSnap } = useSWR(
    () => `sources/${conversation.sourceId}`,
    key =>
      firebase
        .firestore()
        .doc(key)
        .get(),
  )

  const source = sourceSnap.data()

  React.useEffect(() => {
    setDetails({ id: conversation.id, sourceName: source.name })
  }, [conversation.id, source.id])

  return (
    <Router primary={false}>
      <Transcript conversation={conversation} path="/transcript" />
      <Analysis conversation={conversation} source={source} path="/" default />
    </Router>
  )
}

export default Routes
