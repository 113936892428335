import fetch from 'isomorphic-unfetch'

const call = (url, { method = 'GET', body = null, authToken = null }) =>
  fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken ? `Bearer ${authToken}` : undefined,
    },
    body: body ? JSON.stringify(body) : undefined,
  })
    .then(resp => {
      if (resp.ok) {
        return resp
      }
      let error = new Error(resp.statusText ? resp.statusText : resp.status)
      error.resp = resp
      throw error
    })
    .then(resp => resp.json())

export default call
