import React from 'react'
import { navigate } from 'gatsby'

import useAuth from '@hooks/useAuth'

const NonAuthed = ({ component: Component, ...rest }) => {
  const { auth } = useAuth()

  if (auth) {
    navigate('/app', { replace: true })
    return null
  }

  return <Component {...rest} />
}

export default NonAuthed
