import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import Button from '@components/Button'

import addDrift from './drift'

const Testing = ({}) => {
  const [chat, setChat] = useState(false)

  return (
    <>
      <Button onClick={() => setChat('intercom')}>Intercom</Button>
      <Button onClick={() => setChat('zoho')}>Zoho</Button>
      <Button onClick={() => addDrift()}>Drift</Button>
      <Helmet>
        {chat === 'intercom' && <script src="/intercom.js" />}
        {chat === 'zoho' && <script src="/zoho.js" />}
      </Helmet>
    </>
  )
}

export default Testing
