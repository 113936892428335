import React from 'react'
import cn from 'classnames'

import toneColors from '@util/toneColors'

import Line from './Line'

const Transcript = ({ conversation }) => {
  return (
    <>
      <div className="flex">
        {Object.entries(toneColors).map(([key, val]) => (
          <div className="inline-flex mr-2" key={key}>
            <div className={cn('w-6 h-6 mr-1', val)} />
            {key}
          </div>
        ))}
      </div>
      <div className="border rounded">
        {conversation.analysis.map((part, i) => (
          <Line line={part} key={i} />
        ))}
      </div>
    </>
  )
}

export default Transcript
