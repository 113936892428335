import React from 'react'
import useSWR from 'swr'

import call from '@util/call'
import { planByIdAndAnnual } from '@util/plan'
import tw from '@util/tw'
import useAuth from '@hooks/useAuth'

import { format, fromUnixTime } from 'date-fns/fp'
import { compose } from 'ramda'

const formatDate = compose(format('dd MMM yyyy'), fromUnixTime)
import { formatCurrency } from '@util/format'

const TH = tw('th', 'font-medium p-2 text-sm text-gray-600 uppercase')
const TD = tw('td', 'p-2 border-b')

const Preview = ({ plan, annual }) => {
  const { auth } = useAuth()
  const wantedPlan = planByIdAndAnnual[plan][annual.toString()]

  const { data: preview } = useSWR(
    ['/api/user/paymentPreview', wantedPlan],
    async (url, planId) => {
      const authToken = await auth.getIdToken()
      return call(url, {
        method: 'post',
        authToken,
        body: {
          plan: planId,
          uid: auth.uid,
        },
      })
    },
  )

  return (
    <div>
      <table className="table-auto w-full">
        <thead className="bg-gray-200 border-t border-b border-gray-400 text-left">
          <tr>
            <TH>Description</TH>
            <TH>Period</TH>
            <TH>Amount</TH>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TD>Starting Balance</TD>
            <TD></TD>
            <TD>{formatCurrency(preview.starting_balance)}</TD>
          </tr>
          {preview.lines.map(line => (
            <tr key={line.id}>
              <TD>{line.description}</TD>
              <TD>
                {formatDate(line.period.start)} - {formatDate(line.period.end)}
              </TD>
              <TD>{formatCurrency(line.amount)}</TD>
            </tr>
          ))}
          <tr>
            <TD>Ending Balance</TD>
            <TD></TD>
            <TD>{formatCurrency(preview.ending_balance)}</TD>
          </tr>
          <tr>
            <TD>Amount Due Now</TD>
            <TD></TD>
            <TD>{formatCurrency(preview.amount_due)}</TD>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Preview
