import React from 'react'
import useSWR from 'swr'
import { Router, globalHistory } from '@reach/router'

import useFirebase from '@hooks/useFirebase'

import AuthContext from '@context/Auth'

import Authed from '@components/route/Authed'
import NonAuthed from '@components/route/NonAuthed'

import AppLayout from '@layouts/App'

import Signup from '@page/App/Signup'
import Login from '@page/App/Login'
import Home from '@page/App/Home'
import Sources from '@page/App/Sources'
import Conversations from '@page/App/Conversations'
import Agents from '@page/App/Agents'
import Entities from '@page/App/Entities'
import User from '@page/App/User'
import Testing from '@page/App/Testing'

const Routes = () => {
  const firebase = useFirebase()

  const { data: auth, mutate } = useSWR(
    'auth',
    () =>
      new Promise((resolve, reject) => {
        const unregister = firebase.auth().onAuthStateChanged(
          user => {
            unregister()
            resolve(user)
          },
          err => {
            unregister()
            reject(err)
          },
        )
      }),
    { suspense: true, dedupingInterval: 10 * 60 * 1000 },
  )

  React.useEffect(() => {
    const unregister = firebase.auth().onAuthStateChanged(
      user => {
        mutate(user, false)
      },
      err => {
        mutate(err, false)
      },
    )

    return () => unregister()
  }, [])

  const { data: user } = useSWR(
    () => (auth.uid ? `user-${auth.uid}` : null),
    () =>
      firebase
        .firestore()
        .doc(`users/${auth.uid}`)
        .get()
        .then(
          /** @returns {SM.User} */
          snap => snap.data(),
        ),
    { suspense: true },
  )

  React.useEffect(() => {
    return globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH') {
        firebase.analytics().logEvent('page_view', {
          page_location: location.href,
        })
      }
    })
  }, [])

  return (
    <AuthContext.Provider value={{ auth, user }}>
      <Router>
        <NonAuthed component={Signup} path="/app/signup" />
        <NonAuthed component={Login} path="/app/login" />
        <Authed component={AppLayout} default>
          <Home default />
          <Sources path="/app/sources/*" />
          <Conversations path="/app/conversations/*" />
          <Agents path="/app/agents/*" />
          <Entities path="/app/entities/*" />
          <User path="/app/user/*" />
          <Testing path="/app/testing" />
        </Authed>
      </Router>
    </AuthContext.Provider>
  )
}

export default Routes
