import { useContext } from 'react'

import FirebaseContext from '@context/Firebase'

const useFirebase = () => {
  const firebase = useContext(FirebaseContext)

  return firebase
}

export default useFirebase
