import React from 'react'
import { FlexPlot } from '@xmatters/vizlib'

import Line from '../../../Agents/Line'
import Legend from '../../../Agents/Legend'

const getDatax = (key, data) =>
  data
    .map(sentence => [sentence.text.content.length, sentence.sentiment[key]])
    .reduce(
      (acc, val) => [
        ...acc,
        [acc.length ? acc[acc.length - 1][0] + val[0] : val[0], val[1]],
      ],
      [[0, 0]],
    )

const SentimentTimeline = ({ conversation }) => {
  return (
    <>
      <div className="h-32 relative">
        <FlexPlot margin={35}>
          <Line
            data={[
              [
                'sentiment',
                getDatax('score', conversation.sentiment.sentences),
              ],
            ]}
            yDomain={[-1, 1]}
            yTicks={[0, -0.5, -1, 0.5, 1]}
            showBottomAxis={false}
          />
        </FlexPlot>
        <Legend keys={[{ color: 'red', label: 'Sentiment' }]} />
      </div>
      <div className="h-32 relative">
        <FlexPlot margin={35}>
          <Line
            data={[
              [
                'magnitude',
                getDatax('magnitude', conversation.sentiment.sentences),
              ],
            ]}
            showBottomAxis={false}
          />
        </FlexPlot>
        <Legend keys={[{ color: 'red', label: 'Magnitude' }]} />
      </div>
    </>
  )
}

export default SentimentTimeline
