import React, { Suspense } from 'react'
import { SWRConfig } from 'swr'
import { ToastProvider } from 'react-toast-notifications'

import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/performance'
// import 'firebase/storage'

import FirebaseContext from '@context/Firebase'
import ErrorBoundary from '@components/ErrorBoundary'
import Spinner from '@components/Spinner'

import Routes from './Routes'

const AppWideMessage = ({ children }) => (
  <div className="absolute top-0 left-0 w-full h-full bg-gray-400 flex flex-col justify-center items-center text-gray-700">
    {children}
  </div>
)

const App = () => {
  if (!firebase.apps) return null

  const firebaseContext = firebase.apps.length
    ? firebase.apps[0]
    : firebase.initializeApp({
        apiKey: 'AIzaSyDX6BYdNZ50Pkxx1kYvBjYWawqNhhwIEH8',
        authDomain: 'livechat-sentiment.firebaseapp.com',
        databaseURL: 'https://livechat-sentiment.firebaseio.com',
        projectId: 'livechat-sentiment',
        storageBucket: 'livechat-sentiment.appspot.com',
        messagingSenderId: '136670800629',
        appId: '1:136670800629:web:8e16bc824908a045bff3bc',
        measurementId: 'G-SC65GTXP5B',
      })

  const isSSR = typeof window === 'undefined'

  if (process.env.NODE_ENV === 'development') {
    // firebase.firestore().settings({
    //   host: 'localhost:8080',
    //   ssl: false,
    // })
  } else {
    if (!isSSR) {
      firebase.analytics()
      firebase.performance()
    }
  }

  return (
    <ToastProvider autoDismiss>
      <SWRConfig
        value={{
          onError: (err, key) => console.log('swr error', key, err),
          // dedupingInterval: 6 * 60 * 60 * 1000,
          shouldRetryOnError: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          suspense: true,
        }}
      >
        <FirebaseContext.Provider value={firebaseContext}>
          <ErrorBoundary
            fallback={
              <AppWideMessage>
                <p className="text-6xl">😱</p>
                <p className="text-2xl">Uh oh..</p>
                <p className="text-xl">
                  Something went wrong. Try refreshing the page.
                </p>
                <p className="text-xl">If it doesn't work, go outside.</p>
                <p>This error was logged (we will see it).</p>
              </AppWideMessage>
            }
          >
            {!isSSR && (
              <Suspense
                fallback={
                  <AppWideMessage>
                    <Spinner className="text-6xl" />
                    <p className="text-xl">Hang tight while we load stuff.</p>
                  </AppWideMessage>
                }
              >
                <Routes />
              </Suspense>
            )}
          </ErrorBoundary>
        </FirebaseContext.Provider>
      </SWRConfig>
    </ToastProvider>
  )
}

export default App
