import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { Link } from 'gatsby'

import Button from '@components/Button'
import { twcn } from '@util/tw'

const Integration = ({ to, img, name, sub }) => (
  <Link
    to={to}
    className={twcn(
      'border-2 border-gray-300 p-2 shadow cursor-pointer w-48 hover:border-blue-400 rounded m-2',
    )}
  >
    <div className="flex items-center">
      <div className="w-10 mr-2">{img}</div>
      <div>
        <div className="text-lg font-semibold">{name}</div>
        <div className="text-gray-600 text-sm">{sub}</div>
      </div>
    </div>
  </Link>
)

import intercom from '@assets/intercom.png'
// import zoho from '@assets/zoho.png'
import drift from '@assets/drift.png'
import { FaFileCsv } from 'react-icons/fa'
import Page from '@components/Page'

const Add = () => {
  return (
    <Page
      title="Add Source"
      subtitle="Conversations will be pulled from this new source"
      header={
        <Link to="/app/sources">
          <Button className="flex items-center no-underline">
            <FaTimes className="inline-block mr-1" />
            Cancel
          </Button>
        </Link>
      }
    >
      <div className="flex">
        <Integration
          to="/app/sources/add/intercom"
          name="Intercom"
          sub="Conversations"
          img={<img src={intercom} />}
        />
        <Integration
          to="/app/sources/add/drift"
          name="Drift"
          sub="Conversations"
          img={<img src={drift} />}
        />
        {/* <Integration
          to="/app/sources/add/csv"
          name="CSV"
          sub="Import from CSV"
          img={<FaFileCsv className="text-green-600 w-10 h-10" />}
        /> */}
        {/* <Integration
          to="/app/sources/add/zoho"
          name="Zoho SalesIQ"
          sub="Conversations"
          img={<img src={zoho} />}
          soon
        /> */}
      </div>
    </Page>
  )
}

export default Add
