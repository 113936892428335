import React from 'react'
import { FlexPlot } from '@xmatters/vizlibOld'
import { scaleLinear } from 'd3-scale'
import { max, min, sum, extent } from 'd3-array'
import { format } from 'd3-format'

import Axis from './Axis'

const BulletThing = ({ data, domain = extent(data) }) => (
  <FlexPlot
    margin={{
      top: 0,
      left: 20,
      bottom: 20,
      right: 20,
    }}
  >
    {({ chartHeight, chartWidth }) => {
      const xScale = scaleLinear()
        .domain(domain)
        .range([0, chartWidth])

      const dMin = min(data)
      const dMax = max(data)
      const dAvg = sum(data) / data.length

      return (
        <>
          <rect
            x={xScale(domain[0])}
            width={xScale(domain[1]) - xScale(domain[0])}
            y={0}
            height={chartHeight}
            style={{
              fill: 'lightgray',
            }}
          />
          <rect
            x={xScale(dMin)}
            width={xScale(dMax) - xScale(dMin)}
            y={0}
            height={chartHeight}
            style={{
              fill: 'gray',
            }}
          />
          <line
            x1={xScale(0)}
            x2={xScale(0)}
            y1={0}
            y2={chartHeight}
            style={{
              stroke: 'darkgray',
              strokeWidth: 1,
              fill: 'none',
            }}
          />
          <line
            x1={xScale(dAvg)}
            x2={xScale(dAvg)}
            y1={0}
            y2={chartHeight}
            style={{
              stroke: 'red',
              strokeWidth: 3,
              fill: 'none',
            }}
          />
          <Axis
            orientation="bottom"
            ticks={[domain[0], 0, dMin, dMax, dAvg, domain[1]]}
            tickFormat={format('.3~f')}
            scale={xScale}
            transform={`translate(0,${chartHeight})`}
          />
        </>
      )
    }}
  </FlexPlot>
)

export default BulletThing
