import React, { useState } from 'react'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { Link } from 'gatsby'

import Button from '@components/Button'
import Portal from '@components/Portal'

const Confirm = ({ cancel, confirm, confirmLoading, sourceName }) => {
  return (
    <Portal>
      <div
        className="w-screen h-screen absolute top-0 left-0 flex"
        style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
      >
        <div className="max-w-md mx-auto my-auto leading-relaxed">
          <p className="text-xl font-semibold text-white">{sourceName}</p>
          <p className="text-xl font-semibold text-white">
            Are you sure you want to delete this source?
          </p>
          <p className="text-white">
            This will remove all <strong>conversations</strong>,{' '}
            <strong>agents</strong> and <strong>entities</strong> associated
            with this source from your account. This action cannot be undone.
          </p>
          <div className="mt-6 flex justify-end">
            <Button onClick={cancel} disabled={confirmLoading} className="mr-2">
              Cancel
            </Button>
            <Button
              onClick={confirm}
              loading={confirmLoading}
              disabled={confirmLoading}
              className="bg-red-500 text-white hover:bg-red-700"
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  )
}

import { SourceRow } from '@components/Row'
import DateEntry from '@components/DateEntry'

import { startOfDay, addDays, addHours } from 'date-fns'
const nextSyncDate = date => addHours(addDays(startOfDay(date), 1), 12)

const Source = ({ snap, onDelete }) => {
  const [delOpen, setDelOpen] = useState(false)
  const [delLoading, setDelLoading] = useState(false)

  const source = snap.data()

  return (
    <>
      <SourceRow
        sourceType={source.type}
        title={source.name}
        content={
          <>
            <DateEntry title="Added:" date={source.inserted.toDate()} />
            {['intercom', 'drift'].includes(source.type) && (
              <>
                <DateEntry title="Last sync:" date={source.lastSync.toDate()} />
                <DateEntry title="Next sync:" date={nextSyncDate(new Date())} />
              </>
            )}
          </>
        }
        after={
          <Button
            onClick={() => {
              setDelOpen(true)
            }}
            className="ml-auto bg-white text-gray-600 hover:bg-red-500 hover:text-white"
          >
            <FaTrash />
          </Button>
        }
      />

      {delOpen && (
        <Confirm
          sourceName={source.name}
          cancel={() => {
            setDelOpen(false)
            setDelLoading(false)
          }}
          confirm={async () => {
            setDelLoading(true)
            try {
              await onDelete()
            } catch (err) {
              //TODO: show toast
              console.log('wahhaha', err)
            }
            setDelLoading(false)
            setDelOpen(false)
          }}
          confirmLoading={delLoading}
        />
      )}
    </>
  )
}

import useSWR from 'swr'

import useFirebase from '@hooks/useFirebase'
import useAuth from '@hooks/useAuth'
import call from '@util/call'

const List = () => {
  const firebase = useFirebase()
  const { auth } = useAuth()

  const { data: sourceSnaps, mutate } = useSWR('sources', () =>
    firebase
      .firestore()
      .collection('sources')
      .where('userId', '==', auth.uid)
      .get(),
  )

  if (sourceSnaps.empty) {
    return (
      <>
        <p>
          You don't have any sources. Add one to start processing conversations.
        </p>
      </>
    )
  }

  return (
    <>
      {sourceSnaps.docs.map(snap => (
        <Source
          key={snap.id}
          snap={snap}
          onDelete={async () => {
            const authToken = await auth.getIdToken()
            await call(`/api/sources/${snap.id}`, {
              method: 'delete',
              authToken,
            })
            mutate()
          }}
        />
      ))}
    </>
  )
}

import Page from '@components/Page'

export default () => (
  <Page
    title="Sources"
    subtitle="Manage conversation sources"
    header={
      <Link to="/app/sources/add">
        <Button primary className="flex items-center no-underline">
          <FaPlus className="inline-block mr-1" />
          Add Source
        </Button>
      </Link>
    }
  >
    <List />
  </Page>
)
