import React from 'react'
import { Router } from '@reach/router'

import List from './List'
import Add from './Add'
import AddIntercom from './AddIntercom'
import AddZoho from './AddZoho'
import AddDrift from './AddDrift'

const Sources = () => {
  return (
    <>
      <Router primary={false}>
        <List default />
        <Add path="/add" />
        <AddIntercom path="/add/intercom" />
        <AddDrift path="/add/drift" />
        <AddZoho path="/add/zoho" />
      </Router>
    </>
  )
}

export default Sources
