import React from 'react'
import { Router } from '@reach/router'

import List from './List'
import Conversation from './Conversation'

const Conversations = () => {
  return (
    <>
      <Router>
        <List default />
        <Conversation path="/:id/*" />
      </Router>
    </>
  )
}

export default Conversations
