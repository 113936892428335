import React from 'react'
import { Router } from '@reach/router'

import Profile from './Profile'
import Plan from './Plan'
import Billing from './Billing'

const User = () => {
  return (
    <>
      <Router primary={false}>
        <Profile default />
        <Plan path="/plan" />
        {/* <Billing path="/billing" /> */}
      </Router>
    </>
  )
}

export default User
