import React from 'react'
import useSWR from 'swr'
import { Chart } from 'react-charts'
import * as R from 'ramda'
import { useVirtual } from 'react-virtual'

import useAuth from '@hooks/useAuth'
import call from '@util/call'
import Link from '@components/Link'
import EntityIcon from '@components/entity/Icon'
import { EntityName } from '@components/entity/Entity'
import DateEntry from '@components/DateEntry'

import Button from '@components/Button'

const Virtual = ({ size, estimateSize, overscan, RowComponent }) => {
  const parentRef = React.useRef()
  const rowVirtualizer = useVirtual({
    size,
    parentRef,
    overscan,
    estimateSize,
  })

  return (
    <>
      <div
        ref={parentRef}
        className="overflow-auto max-h-screen"
        style={{
          height: '30rem',
        }}
      >
        <div
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.virtualItems.map(RowComponent)}
        </div>
        <div className="sticky bottom-0 pb-3 pr-3 flex justify-end">
          <Button onClick={() => rowVirtualizer.scrollToIndex(0)}>Top</Button>
        </div>
      </div>
    </>
  )
}

const Entity = React.forwardRef(({ entity, start, index, size }, ref) => {
  const [name, ents] = entity

  const series = React.useMemo(
    () => ({
      showPoints: true,
    }),
    [],
  )

  const axes = React.useMemo(
    () => [
      {
        primary: true,
        position: 'bottom',
        type: 'time',
        show: false,
      },
      { position: 'left', type: 'linear', show: false },
    ],
    [],
  )

  const data = React.useMemo(() => {
    const tings = R.pipe(
      R.groupBy(d => d.date),
      R.map(d => ({
        salience: R.mean(d.map(dd => dd.salience)),
        sentiment: R.mean(d.map(dd => dd.sentiment.score)),
        magnitude: R.mean(d.map(dd => dd.sentiment.magnitude)),
      })),
      R.toPairs,
      R.map(([date, vals]) => [new Date(date), vals]),
      R.sortBy(d => d[0]),
    )(ents)

    return [
      {
        label: 'Sentiment',
        data: tings.map(([x, vals]) => ({ x, y: vals.sentiment })),
      },
      {
        label: 'Magnitude',
        data: tings.map(([x, vals]) => ({ x, y: vals.magnitude })),
      },
      {
        label: 'Salience',
        data: tings.map(([x, vals]) => ({ x, y: vals.salience })),
      },
    ]
  }, [ents])

  return (
    <div
      className="flex items-center h-25 border rounded p-3 mb-2 shadow"
      ref={ref}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: `translateY(${start}px)`,
      }}
    >
      <div className="text-gray-700 mr-6 w-16 flex flex-col items-center">
        <EntityIcon type={ents[0].type} className="h-10 w-10" />
        <span className="text-xs">
          <EntityName type={ents[0].type} />
        </span>
      </div>
      <div className="flex flex-col">
        <div className="font-semibold text-lg">{name}</div>

        <div className="w-48 truncate">
          <DateEntry title="First Seen" date={data[0].data[0].x} />
          {data.length > 1 && (
            <DateEntry
              title="Last Seen"
              date={data[0].data[data[0].data.length - 1].x}
            />
          )}
        </div>
      </div>
      {data[0].data.length > 1 ? (
        <div className="flex-grow ml-5 flex">
          <div className="w-32">
            <div className="h-16 w-64">
              <Chart
                data={data}
                series={series}
                axes={axes}
                tooltip
                grouping={'primary'}
                type="line"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="ml-5 text-gray-600 text-sm h-16">
          Not enough data yet
        </div>
      )}
    </div>
  )
})

const Home = ({}) => {
  const { auth } = useAuth()
  const {
    data: { entities },
  } = useSWR(`/api/entities`, async key => {
    const token = await auth.getIdToken()
    return call(key, { authToken: token })
  })

  if (!entities.length) {
    return (
      <p>
        No entities found. <Link to="/app/sources/add">Add a Source</Link> to
        start syncing conversations and extract entities from them.
      </p>
    )
  }

  return (
    <>
      <Virtual
        size={entities.length}
        overscan={3}
        estimateSize={React.useCallback(() => 100, [])}
        RowComponent={({ index, measureRef, start, size }) => (
          <Entity
            entity={entities[index]}
            ref={measureRef}
            index={index}
            start={start}
            key={index}
            size={size}
          />
        )}
      />
    </>
  )
}

import Page from '@components/Page'

export default () => (
  <Page title="Entities" subtitle="Entities mentioned in conversations">
    <Home />
  </Page>
)
