import React from 'react'
import { format, formatDistance } from 'date-fns'

const DateDistance = ({ date, ...rest }) => (
  <abbr title={format(date, 'MMM do, yyyy @ h:mm a')} {...rest}>
    {formatDistance(date, new Date(), {
      addSuffix: true,
    })}
  </abbr>
)

const DateEntry = ({ date, title, ...rest }) => (
  <div className="text-gray-600 text-sm" {...rest}>
    <span className="mr-1">{title}</span>
    <DateDistance date={date} />
  </div>
)

export default DateEntry
