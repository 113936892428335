import React from 'react'
import {
  FaHashtag,
  FaLocationArrow,
  FaDollarSign,
  FaCalendar,
  FaQuestionCircle,
  FaUser,
  FaMapMarkerAlt,
  FaBuilding,
  FaCalendarDay,
  FaPhone,
  FaPalette,
  FaShoppingCart,
  FaShapes,
} from 'react-icons/fa'

const EntityIcon = ({ type, ...rest }) => {
  const icon = {
    UNKNOWN: FaQuestionCircle,
    PERSON: FaUser,
    LOCATION: FaMapMarkerAlt,
    ORGANIZATION: FaBuilding,
    EVENT: FaCalendar,
    WORK_OF_ART: FaPalette,
    CONSUMER_GOOD: FaShoppingCart,
    OTHER: FaShapes,
    PHONE_NUMBER: FaPhone,
    ADDRESS: FaLocationArrow,
    DATE: FaCalendarDay,
    NUMBER: FaHashtag,
    PRICE: FaDollarSign,
  }

  let Component = icon['UNKNOWN']

  if (icon[type]) Component = icon[type]

  return <Component {...rest} />
}

export default EntityIcon
