import React from 'react'
import { Router } from '@reach/router'

import List from './List'
import Agent from './Agent'

const Agents = () => {
  return (
    <>
      <Router>
        <List default />
        <Agent path="/:agentId" />
      </Router>
    </>
  )
}

export default Agents
