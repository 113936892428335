import React from 'react'

import Menu from './Menu'
import useAuth from '@hooks/useAuth'

import { isAfter, fromUnixTime, formatDistance } from 'date-fns/esm'
import Link from '@components/Link'
import SEO from '@components/seo'
import Hotjar from '@components/Hotjar'

const TrialBanner = ({ endDate }) => {
  const now = new Date()
  const end = fromUnixTime(endDate)
  return (
    <div className="bg-blue-200 text-center text-sm text-white p-2">
      Your trial {isAfter(end, now) ? 'ends' : 'ended'}{' '}
      {formatDistance(end, now, {
        addSuffix: true,
      })}
      . <Link to="/app/user/billing">Add a payment method</Link> or{' '}
      <Link to="/app/user/plan">pick a plan</Link> now.
    </div>
  )
}

const App = ({ children }) => {
  const { user } = useAuth()

  return (
    <div className="flex">
      <SEO title="App" />
      <Menu className="bg-blue-400 h-screen flex flex-col" />
      <main className="bg-white h-screen overflow-y-scroll w-full">
        {/* {user.stripe.status === 'trialing' && (
          <TrialBanner endDate={new Date(user.stripe.current_period_end)} />
        )} */}
        {children}
      </main>

      <Hotjar />
    </div>
  )
}

export default App
