import React from 'react'
import { styled } from '@util/tw'
import Link from '@components/Link'

const P = styled('p', 'mt-2')

const Home = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="max-w-lg">
          <P className="font-xxl font-semibold text-center">
            👋 thanks for trying out our beta!
          </P>
          <div>
            <P>
              We know things looks a bit rough, but users like you give
              invaluable feedback in this process for the direction of our
              product.
            </P>
            <P>
              You will be rewarded handsomely for your feedback once we launch.
              Please don't hesitate to contact us about any feedback you have!
            </P>
            <P>
              Get started in minutes by{' '}
              <Link to="/app/sources/add">Adding a Source</Link>, and discover
              how much easier analyzing live chats can be!
            </P>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
