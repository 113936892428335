import React from 'react'

import PriceCard from '@components/pricing/PriceCard'

import { OnPlan } from './OnPlan'

export const PlanCard = ({
  size,
  prices,
  features,
  onClick,
  isTrial,
  isPlan,
  bottom,
}) => (
  <PriceCard
    size={size}
    prices={prices}
    features={features}
    top={
      <>
        <OnPlan trial={isTrial} isPlan={isPlan} />
      </>
    }
    card={{
      wrap: 'md:w-1/3 lg:w-1/4',
      card:
        'shadow-none hover:shadow-xl cursor-pointer relative overflow-hidden',
    }}
    onClick={onClick}
    bottom={bottom}
  />
)
