import React from 'react'

import EntityIcon from './Icon'
import BulletThing from './BulletThing'

const Part = ({ title, subtitle, bulletThing }) => (
  <div className="flex">
    <div className="w-1/5 text-right">
      <div className="font-semibold text-sm">{title}</div>
      <div className="text-xs text-gray-700">{subtitle}</div>
    </div>
    <div className="h-12 w-4/5">
      <BulletThing {...bulletThing} />
    </div>
  </div>
)

export const EntityName = ({ type }) => {
  const names = {
    UNKNOWN: 'Unknown',
    PERSON: 'Person',
    LOCATION: 'Location',
    ORGANIZATION: 'Organization',
    EVENT: 'Event',
    WORK_OF_ART: 'Work of Art',
    CONSUMER_GOOD: 'Consumer Good',
    OTHER: 'Other',
    PHONE_NUMBER: 'Phone Number',
    ADDRESS: 'Address',
    DATE: 'Date',
    NUMBER: 'Number',
    PRICE: 'Price',
  }

  if (!names[type]) return names['UNKNOWN']

  return names[type]
}

export const Entity = ({ name, data, maxMagnitude, ...rest }, ref) => (
  <div className="border-b p-2 mb-2" {...rest} ref={ref}>
    <div className="flex items-center">
      <div className="mr-2 bg-gray-300 rounded p-1">{name}</div>
      <div className="text-gray-700 mr-4">x{data.length}</div>
      {[...new Set(data.map(e => e.type))].map(type => (
        <div key={type} className="flex items-center text-gray-700 ml-2">
          <EntityIcon type={type} className="mr-1" /> <EntityName type={type} />
        </div>
      ))}
    </div>
    <div className="mt-2">
      <Part
        title="Salience"
        subtitle="Importance of entity in text"
        bulletThing={{
          data: data.map(e => e.salience),
          domain: [0, 1],
        }}
      />

      <Part
        title="Sentiment Score"
        subtitle="Overall emotion towards entity"
        bulletThing={{
          data: data.map(e => e.sentiment.score),
          domain: [-1, 1],
        }}
      />

      <Part
        title="Sentiment Magnitude"
        subtitle="Strength of emotion"
        bulletThing={{
          data: data.map(e => e.sentiment.magnitude),
          domain: [0, maxMagnitude],
        }}
      />
    </div>
  </div>
)

export default React.memo(React.forwardRef(Entity))
