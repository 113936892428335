import React from 'react'

const Checkbox = ({ label, ...rest }) => (
  <div className="mb-3">
    <label className="flex items-start md:items-center">
      <input type="checkbox" className="mt-1 md:mt-0" {...rest} />
      <span className="text-sm ml-2">{label}</span>
    </label>
  </div>
)

export default Checkbox
