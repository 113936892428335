import React, { Suspense } from 'react'
import useSWR from 'swr'

import { FlexPlot } from '@xmatters/vizlib'

import useAuth from '@hooks/useAuth'
import useFirebase from '@hooks/useFirebase'
import call from '@util/call'
import Link from '@components/Link'
import Spinner from '@components/Spinner'

import Radar from './Radar'
import Legend from './Legend'

const Agent = ({ stats, agentId }) => {
  const firebase = useFirebase()
  const { auth, user } = useAuth()

  const { data: agent } = useSWR(
    agentId,
    async key => {
      const snap = await firebase
        .firestore()
        .collection('agents')
        .where('id', '==', key)
        .where('userId', '==', auth.uid)
        .get()

      return snap.docs[0].data()
    },
    { suspense: false },
  )

  return (
    <>
      <Link to={`/app/agents/${agentId}`}>
        {agent ? agent.name : `${agentId} (loading)`}
      </Link>
      <div className="h-64 relative">
        <FlexPlot margin={20}>
          <Radar
            data={React.useMemo(() => {
              const agentTones = stats.data.reduce(
                (acc, d) => ({ ...acc, [d.tone_id]: d.count }),
                {},
              )

              const faf = [
                'excited',
                'polite',
                'sympathetic',
                'satisfied',
                'sad',
                'frustrated',
                'impolite',
              ].map(tone => ({
                tone: tone,
                convo: agentTones[tone] || 0,
              }))

              return faf
            }, [stats])}
            series={[{ key: 'convo', color: 'green' }]}
            valueKey="tone"
          />
        </FlexPlot>
        <Legend keys={[{ color: 'green', label: 'Sent' }]} />
      </div>
    </>
  )
}

const Agents = ({}) => {
  const { auth, user } = useAuth()

  const { data: agentStats } = useSWR('/api/agents', async key => {
    const token = await auth.getIdToken()
    return call(key, { authToken: token })
  })

  const agents = Object.keys(agentStats)

  if (!agents.length) {
    return (
      <p>
        No agents found. <Link to="/app/sources/add">Add a Source</Link> to
        start syncing agents that participate in conversations.
      </p>
    )
  }

  return (
    <>
      <div className="flex flex-row flex-wrap justify-center items-center">
        {agents.map(key => (
          <div className="w-1/2 lg:w-1/3 p-2" key={key}>
            <div className="border rounded p-2">
              <Suspense fallback={<Spinner />}>
                <Agent agentId={key} stats={agentStats[key]} />
              </Suspense>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

import Page from '@components/Page'
import tones from '@util/toneColors'

export default () => (
  <Page title="Agents" subtitle="Agent performance overview">
    <Agents />
  </Page>
)
