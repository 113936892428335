import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { useForm } from 'react-hook-form'
import { MdLock } from 'react-icons/md'
import { useToasts } from 'react-toast-notifications'
import { navigate } from 'gatsby'
import { mutate } from 'swr'

import Page from '@components/Page'
import Button from '@components/Button'
import Input, { classNames as inputCn } from '@components/form/Input'
import Label from '@components/form/Label'
import useAuth from '@hooks/useAuth'
import call from '@util/call'
import { twcn } from '@util/tw'

export const BillingForm = ({ onSuccess, onFail, info, buttonText }) => {
  const stripe = useStripe()
  const elements = useElements()
  const {
    register,
    errors,
    setError,
    clearError,
    handleSubmit,
    formState,
  } = useForm()
  const { auth } = useAuth()

  const onSubmit = async values => {
    try {
      const authToken = await auth.getIdToken()
      const setupIntent = await call('/api/user/getSetupIntent', {
        method: 'post',
        authToken,
      })

      const res = await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: values.name,
          },
        },
      })

      if (res.error) {
        setError('card', res.error.type, res.error.message)
        return
      } else {
        clearError('card')
      }

      await call('/api/user/paymentMethod', {
        method: 'post',
        authToken,
        body: {
          payment_method: res.setupIntent.payment_method,
        },
      })

      await onSuccess()
    } catch (err) {
      await onFail(err)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          ref={register({ required: 'This field is required.' })}
          label="Name on Card"
          name="name"
          error={errors?.name?.message}
        />
        <Label label="Card Details" error={errors?.card?.message}>
          <div className={twcn(inputCn(errors?.card?.message), 'p-2')}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    lineHeight: '24px',
                  },
                },
              }}
            />
          </div>
        </Label>
        {info}
        <Button
          type="submit"
          disabled={!stripe || !elements || formState.isSubmitting}
          loading={formState.isSubmitting}
          primary
          full
          className="flex items-center justify-center"
        >
          <MdLock className="mr-1" /> {buttonText}
        </Button>
      </form>
    </>
  )
}

const stripePromise = loadStripe(process.env.STRIPE_PK)

export const Billing = ({ ...rest }) => {
  return (
    <Elements stripe={stripePromise}>
      <BillingForm {...rest} />
    </Elements>
  )
}

export default () => {
  const { user } = useAuth()
  const { addToast } = useToasts()

  React.useEffect(() => {
    if (user?.stripe?.hasPaymentMethod) {
      addToast(
        'You already have a payment method associated with your account. Remove it to add a new one.',
        {
          appearance: 'warning',
        },
      )
      navigate('/app/user')
    }
  }, [user])

  return (
    <Page title="Billing" subtitle="Add a payment method">
      <Billing
        onSuccess={async () => {
          addToast('Payment method added.', {
            appearance: 'success',
          })
          mutate('/api/user/billing')
          navigate('/app/user')
        }}
        onFail={async () => {
          addToast(
            'There was a problem adding this payment method. Try again in a few moments, or contact support.',
            {
              appearance: 'error',
            },
          )
        }}
        info={
          <>
            <div className="rounded bg-blue-300 border-blue-700 my-2 p-2 text-sm font-light text-white text-center">
              <p>
                Your card details are stored securely on our payment provider
                Stripe, which is PCI certified. Card information never touches
                our servers or databases.
              </p>
              <p>
                By adding your card you authorise Sentimentor to send
                instructions to the financial institution that issued your card
                to take payments from your card account in accordance with the
                Terms of Service.
              </p>
            </div>
          </>
        }
        buttonText="Save"
      />
    </Page>
  )
}
