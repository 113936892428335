import React from 'react'

import SourceLogo from './SourceLogo'

const Row = React.forwardRef(
  ({ icon, title = null, content = null, after = null, ...rest }, ref) => {
    return (
      <>
        <div
          className="flex items-center h-25 border rounded p-3 mb-2 shadow"
          {...rest}
          ref={ref}
        >
          {icon}
          <div className="flex flex-col">
            <div className="font-semibold text-lg">{title}</div>
            {content}
          </div>
          {after}
        </div>
      </>
    )
  },
)

export const SourceRow = ({ sourceType, ...rest }) => (
  <Row
    icon={<SourceLogo type={sourceType} className="w-16 mr-6" />}
    {...rest}
  />
)

export default Row
